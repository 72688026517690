import React, { useEffect } from 'react';
import WebinarPage from '../components/webinar-page';
import '../styles/webinar-page.css';
///////////////////////////////////////////////////////////////////////////////////////////////////////////
const WebinarMasteringTrademark = () => {
  //
  const webinarUrl = "https://webinarkit.com/webinar/registration/oneclick/67bc2ca2d0dd86508e900d54?date=jit_15";
  const title = "Trademark Mastery 2025: All That You Must Know in 2025";
  const canonical = "/webinar-mastering-trademark-protection-a-comprehensive-guide";
  const introAuthor = "Bao Tran is an experienced trademark attorney and the CEO of PatentPC."
  const lessons = [
    "The essentials of trademark protection and registration.",
    "How to avoid common trademark pitfalls and legal challenges.",
    "Strategies to strengthen and enforce your brand rights.",
    "Tips on global trademark management and expansion."
  ]
  const btnTxt = "Watch Webinar Now";
  useEffect(() => {
    document?.body.classList.add('no-home');
    //
  }, []);

  return (
    <>
    <WebinarPage webinarUrl={webinarUrl} introAuthor={introAuthor} title={title} canonical={canonical} lessons={lessons} btnTxt={btnTxt}></WebinarPage>
    </>
  )
};

export default WebinarMasteringTrademark;